var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("教师课时统计列表")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: {
                multiple: "",
                filterable: "",
                placeholder: "请选择课程",
              },
              model: {
                value: _vm.courseIdSet,
                callback: function ($$v) {
                  _vm.courseIdSet = $$v
                },
                expression: "courseIdSet",
              },
            },
            _vm._l(_vm.courseList, function (item) {
              return _c("el-option", {
                key: item.course_id,
                attrs: { label: item.name, value: item.course_id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-select",
              attrs: { filterable: "", placeholder: "请选择统计周期" },
              model: {
                value: _vm.periodVal,
                callback: function ($$v) {
                  _vm.periodVal = $$v
                },
                expression: "periodVal",
              },
            },
            _vm._l(_vm.periodList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  label:
                    item.startTime.substring(0, 10) +
                    "~" +
                    item.endTime.substring(0, 10),
                  value: item.id,
                },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "姓名/工号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.employeeName,
              callback: function ($$v) {
                _vm.employeeName = $$v
              },
              expression: "employeeName",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "small" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
          _c(
            "download-excel",
            {
              staticClass: "inline-block",
              attrs: {
                data: _vm.statRes,
                fields: _vm.json_fields,
                name: "教师课时统计列表查询结果.xls",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: {
                    type: "primary",
                    size: "small",
                    title: "若想多出所有数据，请讲每页条数设置为最大",
                  },
                },
                [_vm._v("导出到Excel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.changeHour },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabMapOptions, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { label: item.label, name: item.key } },
            [
              _c(
                "keep-alive",
                [
                  _c(
                    "el-row",
                    { staticClass: "row-bg pr-15" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "font-size": "10px",
                              },
                              attrs: {
                                stripe: "",
                                data: _vm.statRes,
                                border: "",
                                "highlight-current-row": "",
                                size: "mini",
                                "empty-text": "暂无符合查询条件的数据",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "loginName",
                                  label: "工号",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "",
                                  prop: "teacherName",
                                  label: "员工姓名",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "courseTitle",
                                  label: "课程名称",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "课堂角色", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.role === "1"
                                            ? _c("span", [_vm._v("讲师")])
                                            : scope.row.role === "2"
                                            ? _c("span", [_vm._v("助教")])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "scheduleLong",
                                  label: "课时",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.scheduleLong)
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "scheduleTimes",
                                  label: "排课次数",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "durationGt10",
                                  label: "课时|(课容大于10)",
                                  align: "center",
                                  "render-header": _vm.renderheader,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "durationLt10",
                                  label: "课时|(课容小于等于10)",
                                  align: "center",
                                  "render-header": _vm.renderheader,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "",
                                  prop: "statDateRange",
                                  label: "统计周期",
                                  align: "center",
                                  width: "215px",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "",
                                  prop: "createTime",
                                  label: "生成时间",
                                  align: "center",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  scope.row.createTime
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.pagination.currentPage,
                              "page-sizes": _vm.pagination.pageSizes,
                              "page-size": _vm.pagination.pageSize,
                              layout: _vm.pagination.layout,
                              total: _vm.pagination.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }