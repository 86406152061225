<template>
  <el-row class="table">
    <span class="title">教师课时统计列表</span>
    <el-row class="row-bg">
      <el-select
        v-model="courseIdSet"
        multiple
        filterable
        placeholder="请选择课程"
        class="tb-smselect"
      >
        <el-option
          v-for="item in courseList"
          :key="item.course_id"
          :label="item.name"
          :value="item.course_id"
        ></el-option>
      </el-select>
      <el-select
        v-model="periodVal"
        filterable
        placeholder="请选择统计周期"
        class="tb-select"
      >
        <el-option
          v-for="item in periodList"
          :key="item.id"
          :label="item.startTime.substring(0, 10) + '~' + item.endTime.substring(0, 10)"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-input
        v-model="employeeName"
        placeholder="姓名/工号"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>

      <el-button type="primary" size="small" @click="search" class="tb-button">查询</el-button>
      <el-button type="info" size="small" @click="reset" class="tb-button">重置</el-button>
      <download-excel :data="statRes" :fields="json_fields" name="教师课时统计列表查询结果.xls" class="inline-block">
        <el-button
          type="primary"
          size="small"
          class="tb-button"
          title="若想多出所有数据，请讲每页条数设置为最大"
        >导出到Excel</el-button>
      </download-excel>
      <!-- <el-button type="danger" size="small" @click="delTeacherLessonStat" class="tb-button">删除选中</el-button> -->
    </el-row>

    <el-tabs v-model="activeName" @tab-click="changeHour">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <keep-alive>
          <el-row class="row-bg pr-15">
            <el-col :span="24">
              <el-table
                stripe
                :data="statRes"
                border
                highlight-current-row
                size="mini"
                empty-text="暂无符合查询条件的数据"
                @selection-change="handleSelectionChange"
                style="width: 100%;font-size: 10px"
              >
                <!-- <el-table-column fixed type="selection" width="50"></el-table-column> -->
                <el-table-column prop="loginName" label="工号" align="center"></el-table-column>
                <el-table-column fixed prop="teacherName" label="员工姓名" align="center"></el-table-column>
                <el-table-column prop="courseTitle" label="课程名称" align="center"></el-table-column>
                <el-table-column label="课堂角色" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.role === '1'">讲师</span>
                    <span v-else-if="scope.row.role === '2'">助教</span>
                  </template>
                </el-table-column>
                <el-table-column prop="scheduleLong" label="课时" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click="viewDetail(scope.row)"
                      type="text"
                      size="small"
                    >{{scope.row.scheduleLong}}</el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="scheduleTimes" label="排课次数" align="center"></el-table-column>
                <el-table-column
                  prop="durationGt10"
                  label="课时|(课容大于10)"
                  align="center"
                  :render-header="renderheader"
                ></el-table-column>
                <el-table-column
                  prop="durationLt10"
                  label="课时|(课容小于等于10)"
                  align="center"
                  :render-header="renderheader"
                ></el-table-column>
                <el-table-column fixed prop="statDateRange" label="统计周期" align="center" width="215px"></el-table-column>
                <el-table-column fixed prop="createTime" label="生成时间" align="center" width="150">
                  <template slot-scope="scope">
                    <span>{{scope.row.createTime | dateFormat }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :page-sizes="pagination.pageSizes"
                :page-size="pagination.pageSize"
                :layout="pagination.layout"
                :total="pagination.total"
              ></el-pagination>
            </el-col>
          </el-row>
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import { delLessonStat, getListTeacherLessonStat, getListPeriod, getListMyLessonStat } from '@/api/statistics'

export default {
  components: { downloadExcel: JsonExcel },
  data () {
    return {
      json_fields: {
        '统计周期': 'statDateRange',
        '员工姓名': 'teacherName',
        '工号': 'loginName',
        '课程名称': 'courseTitle',
        '课堂角色': 'role',
        '课时': 'scheduleLong',
        '排课次数': 'scheduleTimes',
        '课时|(课容大于10)': 'durationGt10',
        '课时|(课容小于等于10)': 'durationLt10'
      },
      activeName: 'myHour',
      tabMapOptions: [],
      // 检索条件
      employeeName: null,
      minStartTime: null,
      maxStartTime: null,
      courseIdSet: [], // 课程ID
      dateRangeStartTime: [],
      // 日期选择器配置
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      courseList: [], // 课程列表
      periodList: [], // 统计周期列表
      statRes: [], // 查询结果
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 500, 1000],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      multipleSelection: [],
      periodVal: ''
    }
  },
  watch: {
    dateRangeDate (n, o) {
      this.minDate = n[0]
      this.maxDate = n[1]
    },
    dateRangeStartTime (n, o) {
      this.minStartTime = Boolean(n) ? n[0] + ' 00:00:00' : null
      this.maxStartTime = Boolean(n) ? n[1] + ' 23:59:59' : null
    }
  },
  created () {
    let perms = JSON.parse(localStorage.getItem('perms'))
    perms.findIndex(list => {
      if (list === 'statistics:allClassHour') {
        this.tabMapOptions.push({
          label: '全部课时',
          key: 'allHour'
        })
      } else if (list === 'statistics:myClassHour') {
        this.tabMapOptions.push({
          label: '我的课时',
          key: 'myHour'
        })
      }
    })
    this.getListPeriod()
    this.listTeacherLessonStat()
  },
  methods: {
    async getListPeriod () {
      const res = await getListPeriod({
        pageNum: 1,
        pageSize: 1000
      })
      this.periodList = res.body.list
    },
    reset () {
      this.courseIdSet = []
      this.periodVal = null
      this.dateRangeStartTime = null
      this.employeeName = null
    },

    search () {
      this.pagination.currentPage = 1
      if (this.activeName === 'allHour') {
        this.listTeacherLessonStat()
      } else {
        this.getListMyLessonStat()
      }
    },

    // 教师排课列表
    getListMyLessonStat () {
      getListMyLessonStat({
        periodId: this.periodVal ? this.periodVal : '',
        courseIdSet: this.courseIdSet,
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.state === 'success') {
          let statRes = res.body.list
          statRes.forEach((v, k, arr) => {
            if (v.role === 1) {
              v.role = '讲师'
            } else if (v.role === 2) {
              v.role = '助教'
            }
            if (!v.scheduleTimesGt10) {
              v.scheduleTimesGt10 = 0
            }
            if (!v.scheduleTimesLt10) {
              v.scheduleTimesLt10 = 0
            }
            v.scheduleTimes = v.scheduleTimesGt10 + v.scheduleTimesLt10
            if (!v.durationGt10) {
              v.durationGt10 = 0
            }
            if (!v.durationLt10) {
              v.durationLt10 = 0
            }
            v.durationLt10 = Math.round((v.durationLt10 / 60) * 100) / 100
            v.durationGt10 = Math.round((v.durationGt10 / 60) * 100) / 100
            v.scheduleLong = v.durationGt10 + v.durationLt10
            if (!v.teacherName) {
              v.teacherName = '--'
              v.loginName = '--'
            }
            v.statDateRange = v.startTime.substring(0, 10) + '至' + v.endTime.substring(0, 10)
          })
          this.statRes = statRes
          this.pagination.total = res.body.total
        }
      })
    },
    /**
     * 教师排课统计列表
     */
    listTeacherLessonStat () {
      getListTeacherLessonStat({
        periodId: this.periodVal ? this.periodVal : '',
        employeeName: this.employeeName,
        courseIdSet: this.courseIdSet,
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.state === 'success') {
          let statRes = res.body.list
          statRes.forEach((v, k, arr) => {
            if (v.role === 1) {
              v.role = '讲师'
            } else if (v.role === 2) {
              v.role = '助教'
            }
            if (!v.scheduleTimesGt10) { // 上课次数-课容大于等于10的
              v.scheduleTimesGt10 = 0
            }
            if (!v.scheduleTimesLt10) { // 上课次数-课容小于10的
              v.scheduleTimesLt10 = 0
            }
            v.scheduleTimes = v.scheduleTimesGt10 + v.scheduleTimesLt10 // 上课次数
            if (!v.durationGt10) { // 上课时长（分钟）-课容大于等于10的
              v.durationGt10 = 0
            }
            if (!v.durationLt10) { // 上课时长（分钟）-课容小于10的
              v.durationLt10 = 0
            }
            v.durationLt10 = Math.round((v.durationLt10 / 60) * 100) / 100
            v.durationGt10 = Math.round((v.durationGt10 / 60) * 100) / 100
            v.scheduleLong = v.durationGt10 + v.durationLt10
            if (!v.teacherName) {
              v.teacherName = '--'
              v.loginName = '--'
            }
            v.statDateRange = v.startTime.substring(0, 10) + '至' + v.endTime.substring(0, 10)
          })
          this.statRes = statRes
          this.pagination.total = res.body.total
        }
      })
    },
    /**
     * 删除记录
     */
    delTeacherLessonStat () {
      let $this = this
      let length = this.multipleSelection.length
      let i = 0
      this.multipleSelection.forEach((v, k, arr) => {
        v.startTime = v.startTime.substring(0, 19)
        v.endTime = v.endTime.substring(0, 19)
        v.role = v.role === '讲师' ? 1 : 2
        delLessonStat({
          startTime: v.startTime,
          endTime: v.endTime,
          teacherId: v.teacherId,
          shiftId: v.shiftId,
          role: v.role
        }).then(res => {
          if (res.state === 'success') {
            i++
            if (i >= length) {
              $this.listTeacherLessonStat()
            }
          }
        })
      })
    },

    handleSizeChange (val) {
      this.pagination.pageSize = val
      if (this.activeName === 'allHour') {
        this.listTeacherLessonStat()
      } else {
        this.getListMyLessonStat()
      }
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      if (this.activeName === 'allHour') {
        this.listTeacherLessonStat()
      } else {
        this.getListMyLessonStat()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 表头换行
    renderheader (h, { column, $index }) {
      return h('span', {}, [
        h('span', {}, column.label.split('|')[0]),
        h('br'),
        h('span', {}, column.label.split('|')[1])
      ])
    },

    viewDetail (row) {
      console.log('viewDetail', row)
      this.$router.push({
        path: '/statistics/lessonStatList',
        query: {
          periodId: row.periodId,
          courseId: row.courseId,
          employeeId: row.teacherId,
          role: row.role === '1' ? '讲师' : '助教',
          activeName: this.activeName
        }
      })
    },

    changeHour () {
      if (this.activeName === 'allHour') {
        this.listTeacherLessonStat()
      } else {
        this.getListMyLessonStat()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
